import callRequest from "../helpers/RequestTools.js"

export const QuestionController = {
  createQuestion: (question) => {
    return callRequest("/question", question, "POST")
  },

  updateQuestion: (questionId, question) => {
    return callRequest(`/question/${questionId}`, question, "PUT")
  },

  deleteQuestion: (questionId) => {
    return callRequest(`/question/${questionId}`, null, "DELETE")
  },

  getOneQuestion: (questionId) => {
    return callRequest(`/question/one/${questionId}`)
  },

  getAllQuestions: (filter = {}) => {
    return callRequest("/question/all", filter)
  },

  getAllQuestionClass: (filter = {}) => {
    return callRequest("/question/class/all", filter)
  },

  getAllQuestionCategory: (filter = {}) => {
    return callRequest("/question/category/all", filter)
  },
}
