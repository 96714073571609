export const questionLevels = [
  { levelId: 1, levelName: "Fácil" },
  { levelId: 2, levelName: "Médio" },
  { levelId: 3, levelName: "Difícil" },
]

export const questionTestaments = [
  { testamentId: 0, testamentName: "Sem Testamento" },
  { testamentId: 1, testamentName: "Antigo Testamento" },
  { testamentId: 2, testamentName: "Novo Testamento" },
]
