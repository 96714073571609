import React from "react"

import "./privacy-policy.scss"

export default function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h2>
        <strong>Política de Privacidade</strong>
      </h2>
      <div>
        <p>
          Esta Política de Privacidade descreve nossas políticas e procedimentos sobre a coleta, o
          uso e a divulgação de suas informações quando você usa o Serviço e informa sobre seus
          direitos de privacidade e como a lei o protege. Usamos seus dados pessoais para fornecer e
          aprimorar o Serviço. Ao usar o Serviço, você concorda com a coleta e o uso de informações
          de acordo com esta Política de Privacidade.
        </p>
        <h4>
          <strong>Interpretação e definições</strong>
        </h4>
        <h5>
          <strong>Interpretação</strong>
        </h5>
        <p>
          As palavras cuja letra inicial é maiúscula têm significados definidos nas seguintes
          condições. As definições a seguir terão o mesmo significado, independentemente de
          aparecerem no singular ou no plural.{" "}
        </p>
        <h5>
          <strong>Definições</strong>
        </h5>
        <p>Para os fins desta Política de Privacidade:</p>
        <ul>
          <li>
            <strong>Conta</strong> significa uma conta exclusiva criada para que Você acesse nosso
            Serviço ou partes dele.
          </li>
          <li>
            <strong>Afiliada</strong> significa uma entidade que controla, é controlada por ou está
            sob controle comum com uma parte, sendo que "controle" significa a propriedade de 50% ou
            mais das ações, participação acionária ou outros títulos com direito a voto para eleição
            de diretores ou outra autoridade administrativa.
          </li>
          <li>
            <strong>Aplicativo</strong> refere-se ao Bíblia Play, o programa de software fornecido
            pela Empresa. Empresa (referida como "a Empresa", "Nós", "Nos" ou "Nosso" neste
            Contrato) refere-se à Bíblia Play.
          </li>
          <li>
            <strong>País</strong> a que se refere: Brasil
          </li>
          <li>
            <strong>Dispositivo</strong> significa qualquer dispositivo que possa acessar o Serviço,
            como um computador, um celular ou um tablet digital.
          </li>
          <li>
            <strong>Dados pessoais</strong> são quaisquer informações relacionadas a um indivíduo
            identificado ou identificável.{" "}
          </li>
          <li>
            <strong>O serviço</strong> refere-se ao aplicativo.
          </li>
          <li>
            <strong>Provedor de serviço</strong>s significa qualquer pessoa física ou jurídica que
            processa os dados em nome da Empresa. Refere-se a empresas terceirizadas ou indivíduos
            empregados pela Empresa para facilitar o Serviço, para fornecer o Serviço em nome da
            Empresa, para realizar serviços relacionados ao Serviço ou para auxiliar a Empresa na
            análise de como o Serviço é usado.
          </li>
          <li>
            <strong>Dados de uso</strong> referem-se aos dados coletados automaticamente, gerados
            pelo uso do Serviço ou da própria infraestrutura do Serviço (por exemplo, a duração de
            uma visita à página).
          </li>
          <li>
            <strong>Você</strong> significa o indivíduo que está acessando ou usando o Serviço, ou a
            empresa ou outra entidade legal em nome da qual esse indivíduo está acessando ou usando
            o Serviço, conforme aplicável.
          </li>
        </ul>
        <h5>
          <strong>Coleta e uso de seus dados</strong>
        </h5>
        pessoais Tipos de dados coletados Dados pessoais Ao usar nosso Serviço, podemos solicitar
        que você nos forneça determinadas informações de identificação pessoal que podem ser usadas
        para contatá-lo ou identificá-lo. As informações de identificação pessoal podem incluir, mas
        não estão limitadas a: Endereço de e-mail Nome e sobrenome Número de telefone Endereço,
        estado, província, CEP/código postal, cidade Dados de uso Dados de uso Os Dados de Uso são
        coletados automaticamente ao usar o Serviço. Os Dados de Uso podem incluir informações como
        o endereço de Protocolo de Internet do seu Dispositivo (por exemplo, endereço IP), tipo de
        navegador, versão do navegador, as páginas do nosso Serviço que você visita, a hora e a data
        da sua visita, o tempo gasto nessas páginas, identificadores exclusivos de dispositivos e
        outros dados de diagnóstico. Quando você acessa o Serviço por ou por meio de um dispositivo
        móvel, podemos coletar determinadas informações automaticamente, incluindo, entre outras, o
        tipo de dispositivo móvel que você usa, a ID exclusiva do seu dispositivo móvel, o endereço
        IP do seu dispositivo móvel, o sistema operacional do seu dispositivo móvel, o tipo de
        navegador de Internet móvel que você usa, identificadores exclusivos de dispositivos e
        outros dados de diagnóstico. Também podemos coletar informações que seu navegador envia
        sempre que você visita nosso Serviço ou quando você acessa o Serviço por meio de um
        dispositivo móvel. Informações coletadas durante o uso do aplicativo Durante o uso do Nosso
        Aplicativo, a fim de fornecer recursos do Nosso Aplicativo, podemos coletar, com Sua
        permissão prévia: Informações sobre sua localização Fotos e outras informações da câmera e
        da biblioteca de fotos do seu dispositivo Usamos essas informações para fornecer recursos de
        Nosso Serviço, para melhorar e personalizar Nosso Serviço. As informações podem ser
        carregadas nos servidores da Empresa e/ou no servidor de um provedor de serviços ou podem
        ser simplesmente armazenadas em seu dispositivo. Você pode ativar ou desativar o acesso a
        essas informações a qualquer momento, por meio das configurações do seu dispositivo. Uso de
        seus dados pessoais A Empresa pode usar os Dados Pessoais para as seguintes finalidades:
        Para fornecer e manter nosso Serviço, inclusive para monitorar o uso do nosso Serviço. Para
        gerenciar sua conta: para gerenciar seu registro como usuário do Serviço. Os Dados Pessoais
        fornecidos por Você podem lhe dar acesso a diferentes funcionalidades do Serviço que estão
        disponíveis para Você como um usuário registrado. Para a execução de um contrato: o
        desenvolvimento, o cumprimento e a execução do contrato de compra dos produtos, itens ou
        serviços que Você adquiriu ou de qualquer outro contrato conosco por meio do Serviço. Para
        entrar em contato com o Usuário: Para entrar em contato com Você por e-mail, telefonemas,
        SMS ou outras formas equivalentes de comunicação eletrônica, como notificações push de um
        aplicativo móvel sobre atualizações ou comunicações informativas relacionadas às
        funcionalidades, produtos ou serviços contratados, incluindo as atualizações de segurança,
        quando necessário ou razoável para sua implementação. Para fornecer a você notícias, ofertas
        especiais e informações gerais sobre outros produtos, serviços e eventos que oferecemos e
        que são semelhantes aos que você já comprou ou solicitou, a menos que você tenha optado por
        não receber essas informações. Para gerenciar suas solicitações: Para atender e gerenciar
        suas solicitações para nós. Para transferências de negócios: Podemos usar suas informações
        para avaliar ou conduzir uma fusão, alienação, reestruturação, reorganização, dissolução ou
        outra venda ou transferência de alguns ou todos os nossos ativos, seja como uma empresa em
        funcionamento ou como parte de um processo de falência, liquidação ou similar, no qual os
        Dados Pessoais mantidos por nós sobre os usuários de nossos Serviços estejam entre os ativos
        transferidos. Para outros fins: Podemos usar suas informações para outros fins, como análise
        de dados, identificação de tendências de uso, determinação da eficácia de nossas campanhas
        promocionais e para avaliar e melhorar nosso Serviço, produtos, serviços, marketing e sua
        experiência. Podemos compartilhar suas informações pessoais nas seguintes situações: Com
        provedores de serviços: Podemos compartilhar suas informações pessoais com os provedores de
        serviços para monitorar e analisar o uso de nosso Serviço, para entrar em contato com você.
        Para transferências de negócios: Podemos compartilhar ou transferir suas informações
        pessoais em conexão com, ou durante as negociações de, qualquer fusão, venda de ativos da
        Empresa, financiamento ou aquisição de todo ou parte de nossos negócios para outra empresa.
        Com afiliadas: Podemos compartilhar suas informações com nossas afiliadas e, nesse caso,
        exigiremos que essas afiliadas respeitem esta Política de Privacidade. As afiliadas incluem
        nossa empresa controladora e quaisquer outras subsidiárias, parceiros de joint venture ou
        outras empresas que controlamos ou que estão sob controle comum conosco. Com parceiros
        comerciais: Podemos compartilhar suas informações com nossos parceiros comerciais para lhe
        oferecer determinados produtos, serviços ou promoções. Com outros usuários: quando você
        compartilha informações pessoais ou interage de outra forma nas áreas públicas com outros
        usuários, essas informações podem ser visualizadas por todos os usuários e podem ser
        distribuídas publicamente no exterior. Com seu consentimento: Podemos divulgar suas
        informações pessoais para qualquer outra finalidade com o seu consentimento. Retenção de
        seus dados pessoais A Empresa reterá seus Dados Pessoais somente pelo tempo necessário para
        as finalidades estabelecidas nesta Política de Privacidade. Reteremos e usaremos seus Dados
        Pessoais na medida necessária para cumprir nossas obrigações legais (por exemplo, se formos
        obrigados a reter seus dados para cumprir as leis aplicáveis), resolver disputas e fazer
        cumprir nossos acordos e políticas legais. A Empresa também reterá os Dados de Uso para fins
        de análise interna. Os Dados de Uso geralmente são retidos por um período mais curto, exceto
        quando esses dados são usados para reforçar a segurança ou melhorar a funcionalidade do
        Nosso Serviço, ou quando somos legalmente obrigados a reter esses dados por períodos mais
        longos. Transferência de seus dados pessoais Suas informações, inclusive Dados Pessoais, são
        processadas nos escritórios operacionais da Empresa e em quaisquer outros locais onde as
        partes envolvidas no processamento estejam localizadas. Isso significa que essas informações
        podem ser transferidas para - e mantidas em - computadores localizados fora de seu estado,
        província, país ou outra jurisdição governamental, onde as leis de proteção de dados podem
        ser diferentes daquelas de sua jurisdição. Seu consentimento com esta Política de
        Privacidade, seguido do envio de tais informações, representa sua concordância com essa
        transferência. A Empresa tomará todas as medidas razoavelmente necessárias para garantir que
        seus dados sejam tratados com segurança e de acordo com esta Política de Privacidade, e
        nenhuma transferência de seus dados pessoais ocorrerá para uma organização ou um país, a
        menos que haja controles adequados em vigor, incluindo a segurança de seus dados e outras
        informações pessoais. Excluir seus dados pessoais Você tem o direito de excluir ou solicitar
        que Nós ajudemos a excluir os Dados Pessoais que coletamos sobre você. Nosso Serviço pode
        permitir que você exclua determinadas informações sobre você de dentro do Serviço. Você pode
        atualizar, alterar ou excluir suas informações a qualquer momento entrando em sua conta, se
        tiver uma, e visitando a seção de configurações da conta que permite gerenciar suas
        informações pessoais. Você também pode entrar em contato conosco para solicitar acesso,
        corrigir ou excluir qualquer informação pessoal que tenha fornecido a nós. Observe,
        entretanto, que talvez precisemos reter determinadas informações quando tivermos uma
        obrigação legal ou uma base jurídica para fazê-lo. Divulgação de seus dados pessoais
        Transações comerciais Se a Empresa estiver envolvida em uma fusão, aquisição ou venda de
        ativos, seus dados pessoais poderão ser transferidos. Avisaremos antes que Seus Dados
        Pessoais sejam transferidos e se tornem sujeitos a uma Política de Privacidade diferente.
        Aplicação da lei Em determinadas circunstâncias, a Empresa poderá ser solicitada a divulgar
        seus Dados Pessoais se for obrigada a fazê-lo por lei ou em resposta a solicitações válidas
        de autoridades públicas (por exemplo, um tribunal ou uma agência governamental). Outros
        requisitos legais A Empresa pode divulgar seus Dados Pessoais na crença de boa fé de que tal
        ação é necessária para: Cumprir com uma obrigação legal Proteger e defender os direitos ou a
        propriedade da Empresa Prevenir ou investigar possíveis irregularidades em relação ao
        Serviço Proteger a segurança pessoal dos Usuários do Serviço ou do público Proteger-se
        contra responsabilidade legal Segurança de seus dados pessoais A segurança dos seus dados
        pessoais é importante para nós, mas lembre-se de que nenhum método de transmissão pela
        Internet ou método de armazenamento eletrônico é 100% seguro. Embora nos esforcemos para
        usar meios comercialmente aceitáveis para proteger seus dados pessoais, não podemos garantir
        sua segurança absoluta. Privacidade das crianças Nosso Serviço não se destina a menores de
        13 anos de idade. Não coletamos intencionalmente informações de identificação pessoal de
        menores de 13 anos. Se você for pai ou responsável e estiver ciente de que seu filho nos
        forneceu dados pessoais, entre em contato conosco. Se tomarmos conhecimento de que coletamos
        dados pessoais de menores de 13 anos sem a verificação do consentimento dos pais, tomaremos
        medidas para remover essas informações de nossos servidores. Se precisarmos confiar no
        consentimento como base legal para o processamento de suas informações e seu país exigir o
        consentimento de um dos pais, poderemos exigir o consentimento de seus pais antes de
        coletarmos e usarmos essas informações.{" "}
        <h4>
          <strong>Links para outros sites</strong>
        </h4>
        <p>
          Nosso Serviço pode conter links para outros sites que não são operados por nós. Se você
          clicar em um link de terceiros, será direcionado ao site desses terceiros. Recomendamos
          enfaticamente que você analise a Política de Privacidade de cada site que visitar. Não
          temos controle e não assumimos nenhuma responsabilidade pelo conteúdo, políticas de
          privacidade ou práticas de sites ou serviços de terceiros.
        </p>
        <h4>
          <strong>Alterações a esta Política de Privacidade</strong>
        </h4>
        <p>
          Poderemos atualizar nossa Política de Privacidade periodicamente. Nós o notificaremos
          sobre quaisquer alterações, publicando a nova Política de Privacidade nesta página. Nós o
          informaremos por e-mail e/ou por meio de um aviso destacado em Nosso Serviço, antes que a
          alteração entre em vigor, e atualizaremos a data da "Última atualização" na parte superior
          desta Política de Privacidade. Aconselhamos que você revise esta Política de Privacidade
          periodicamente para verificar se houve alguma alteração. As alterações a esta Política de
          Privacidade entrarão em vigor quando forem publicadas nesta página.
        </p>
        <h3>
          <strong>Entre em contato conosco</strong>
        </h3>
        <p>
          Se tiver alguma dúvida sobre esta Política de Privacidade, entre em contato conosco: Por
          telefone: +55 48 999184182
        </p>
      </div>
    </div>
  )
}
