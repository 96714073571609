import axios from "axios"

const version = "/v1"

async function callRequest(
  url,
  params = null,
  method = "GET",
  headers = {
    "Content-Type": "application/json",
    Source: "7ad863ddeea64f33bb4d671c5445226e67c257c0",
  },
  timeout = 30000,
) {
  let config = {
    baseURL: process.env.REACT_APP_API_HOST,
    url: version + url,
    method,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Cache-Control": "no-cache",
    },
    timeout,
  }

  config.headers = { ...config.headers, ...headers }

  if (!!params) {
    if (method === "GET") {
      config.params = params
    } else {
      config.data = params
    }
  }

  return axios
    .request(config)
    .then((response) => {
      const { status, message = null, content = null } = response?.data || {}

      if (!!status && !!message && !!content) {
        return response.data
      }

      return { status: response.status, content: response.data }
    })
    .catch((error) => {
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.data?.message,
          content: error.response.data,
        }
      }

      return {
        status: 500,
        message: "Ocorreu um erro de comunicação com o servidor. Favor tente mais tarde!",
      }
    })
}

export default callRequest
