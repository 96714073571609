import React from "react"
import { Input, Label } from "reactstrap"

import "./InputTitleGroup.scss"

const InputTitleGroup = ({
  type = "text",
  title = "",
  value = "",
  placeholder = "",
  width = "100%",
  maxLength = 50,
  onChange,
}) => {
  switch (type) {
    case "textarea":
      return (
        <div className="input-title-group">
          <Label>{title}</Label>
          <textarea
            rows="2"
            maxLength={100}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
          ></textarea>
        </div>
      )

    default:
      return (
        <div className="input-title-group" style={{ width }}>
          <Label>{title}</Label>
          <Input
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            maxLength={maxLength}
          />
        </div>
      )
  }
}

export default InputTitleGroup
