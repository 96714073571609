import React from "react"
import ReactDOM from "react-dom/client"

import "./index.css"
import "bootstrap/dist/css/bootstrap.min.css"

import App from "./App.js"

// Font Awesome
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { library } from "@fortawesome/fontawesome-svg-core"
import { BrowserRouter } from "react-router-dom"

// Carregando todos os tipo de ícones Free
library.add(fas, far, fab)

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
)
