import React from "react"
import { useAuth } from "../../hooks/useAuth"

import "./signin.scss"

import logoIcon from "../../assets/images/icon.png"

function SignInPage() {
  const [username, setUsername] = React.useState("")
  const [password, setPassword] = React.useState("")
  const { signIn } = useAuth()

  const handleLogin = async (e) => {
    e.preventDefault()

    if (!!username) {
      if (!!password) {
        signIn(username, password)
      } else {
        alert("Senha não informada")
      }
    } else {
      alert("Usuário não informado")
    }
  }

  return (
    <div className="signin">
      <div className="signin--body">
        <div className="signin--body--left">
          <div className="signin--body--left--image">
            <img src={logoIcon} alt="logo" />
          </div>
          <h1 className="signin--body--left--title">Bíblia Play</h1>
        </div>
        <div className="signin--body--right">
          {
            <form onSubmit={handleLogin}>
              <div className="signin--body--right--username">
                <label htmlFor="username">Usuário:</label>
                <input
                  id="username"
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="signin--body--right--password">
                <label htmlFor="password">Senha:</label>
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="signin--body--right--enter-button">
                <button type="submit">Entrar</button>
              </div>
            </form>
          }
        </div>
      </div>
    </div>
  )
}

export default SignInPage
