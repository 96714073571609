import callRequest from "../helpers/RequestTools.js"

export const AuthController = {
  checkUser: (username, password) => {
    return callRequest(`/auth`, { username, password }, "POST")
  },

  getLastVersion: () => {
    return callRequest(`/auth/last/version`, { projectName: "adm" }, "GET")
  },
}
