import React from "react"

import "./crosswords.scss"

const CrosswordBox = () => {
  return <div className="crosswords-box"></div>
}

function CrosswordBoxPage() {
  const [matriz, setMatriz] = React.useState({ rows: 5, cols: 5 })
  // const [boxes, setBoxes] = React.useState([])

  // React.useEffect(() => {
  //   const rows = 5
  //   const cols = 5

  //   setMatriz({ rows, cols })

  //   let boxes = []

  //   for (let i = 0; i < rows; i++) {
  //     for (let j = 0; j < cols; j++) {
  //       boxes[i] = [...(boxes[i] || []), { row: i, col: j }]
  //     }
  //   }

  //   const questionNumbers = Math.floor(rows * cols * 0.3)

  //   const sortedRowNumber = Math.floor(Math.random() * rows)
  //   const sortedColNumber = Math.floor(Math.random() * cols)

  //   boxes[sortedRowNumber][sortedColNumber] = {
  //     row: sortedRowNumber,
  //     col: sortedColNumber,
  //     question: "A",
  //   }

  //   const rowsRandom = []

  //   // for (let i = 0; i < questionNumbers; i++) {
  //   //   const sortedNumber = Math.floor(Math.random() * rows)

  //   //   console.log("sortedNumber", sortedNumber)
  //   //   const rowNumbers = rowsRandom.filter((row) => row === sortedNumber)
  //   //   console.log("rowNumbers", rowNumbers.length)
  //   //   if (rowNumbers.length < 2) {
  //   //     rowsRandom.push(sortedNumber)
  //   //   } else {
  //   //     rowsRandom.push(-1)
  //   //   }
  //   // }

  //   var myArray = boxes.filter((box) => box.row === 0)
  //   var rand = myArray[(Math.random() * myArray.length) | 0]
  // }, [])

  return (
    <div className="crosswords">
      {Array.from({ length: matriz.rows }).map((_, i) => (
        <div key={i} className="crosswords-row">
          {Array.from({ length: matriz.cols }).map((_, j) => (
            <CrosswordBox key={j} />
          ))}
        </div>
      ))}
    </div>
  )
}

export default CrosswordBoxPage
